import React from "react"
import PropTypes from "prop-types"
import { kebabCase } from "lodash"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"
import Hero from "../components/Hero"
import ContentHero from "../components/ContentHero"
import JobOfferContact from "../components/JobOfferContact"

const Apply = ({ title }) => (
  <p>
    <a
      className="is-small is-light hvr-underline-from-left"
      href={`mailto:jobs@colenio.de?subject=Bewerbung ${encodeURIComponent(
        title
      )}`}
    >
      Bewirb Dich jetzt! →
    </a>
  </p>
)

// TODO: Refactor to apropriate data structure
const BENEFITS = {
  career: {
    tech: `Ob Tech Guru, UX-Experte, Lead Architekt, Principal Engineer oder auch colenio Fellow: Du bestimmst Deinen Weg, und wir unterstützen Dich dabei. Wir bieten eine individuelle Karriereleiter für technische Exzellenz.`,
    legal: `Ob Weiterbildung, Seminar oder Kurse - Du bestimmst Deinen Weg und wir unterstützen Dich dabei.`,
    sales: `Ob Weiterbildung, Seminar oder Kurse - Du bestimmst Deinen Weg und wir unterstützen Dich dabei.`,
  },
  lifeBalance: {
    tech: `Wir sind kein klassisches
    Beratungshaus, das Dich fünf Tage die Woche irgendwo ins Hotel
    schickt. Wir arbeiten gut und gern remote zusammen und wollen,
    dass Du abends in der Regel zuhause bist.`,
    legal: `Wir sind kein klassisches Beratungshaus, das Dich fünf Tage die Woche
    irgendwo ins Hotel schickt. Wir arbeiten gut und gern remote zusammen und wollen,
    dass Du Familie und Arbeit unter einen Hut bringen kannst.`,
    sales: `Wir sind kein klassisches Beratungshaus, das Dich fünf Tage die Woche
    irgendwo ins Hotel schickt. Wir arbeiten gut und gern remote zusammen und wollen,
    dass Du Familie und Arbeit unter einen Hut bringen kannst.`,
  },
  money: {
    tech: `Wir zahlen ein überdurchschnittliches Festgehalt,
    weil Vertrauen die Grundlage unserer Zusammenarbeit ist.
    Ein fairer Leistungsbonus mit klaren einfachen Zielen rundet das Paket ab.`,
    legal: `Wir mögen keine
    Spielchen beim Gehalt mit komplizierten erfolgsabhängigen
    Anteilen. Wir zahlen gutes Geld für gute Leistung, weil
    Vertrauen die Grundlage unserer Zusammenarbeit ist.`,
    sales: `Wir bieten dir ein attraktives Festgehalt sowie ein faires, ausbalanciertes Provisions-Modell. `,
  },
  organization: {
    tech: `Wir bieten einen sehr
    hohen Grad an individueller Freiheit bei der Gestaltung der
    eigenen Arbeitsweise. Ob Home Office oder Büro, ob früh oder
    eher spät: Deine Sache.`,
    legal: `Wir bieten einen sehr
    hohen Grad an individueller Freiheit bei der Gestaltung der
    eigenen Arbeitsweise. Ob Home Office oder Büro, ob früh oder
    eher spät: Deine Sache.`,
    sales: `Wir bieten einen sehr
    hohen Grad an individueller Freiheit bei der Gestaltung der
    eigenen Arbeitsweise. Ob Home Office oder Büro, ob früh oder
    eher spät: Deine Sache. Des Weiteren genießt du weitreichende Handlungsfreiheit in der Gestaltung der Kundenbeziehungen und des Angebotsportfolios`,
  },
  coCreation: {
    tech: `Bei uns hast Du direkten
    Einfluss auf Dein Arbeitsumfeld. Frische und mutige Ideen zu
    Arbeitsprozessen, Tools, unserer Kultur oder unserer
    strategischen Ausrichtung bilden den Antrieb für unsere
    gemeinsame Weiterentwicklung.`,
    legal: `Bei uns hast Du direkten
    Einfluss auf Dein Arbeitsumfeld. Frische und mutige Ideen zu
    Arbeitsprozessen, Tools, unserer Kultur oder unserer
    strategischen Ausrichtung bilden den Antrieb für unsere
    gemeinsame Weiterentwicklung.`,
    sales: `Bei uns hast Du direkten
    Einfluss auf Dein Arbeitsumfeld. Frische und mutige Ideen zu
    Arbeitsprozessen, Tools, unserer Kultur oder unserer
    strategischen Ausrichtung bilden den Antrieb für unsere
    gemeinsame Weiterentwicklung. Bei uns gibt es darüber hinaus flache Hierachien, schnelle Entscheidungen und kurze Turnaround - Zeiten.`,
  },
}

const JOB_CONTACTS = {
  cme: {
    name: "Christopher Menzel",
    phone: "0151 6713-7529",
    phoneInternational: "+4915167137529",
    img: "/img/Chris-Menzel.jpg",
  },
  mad: {
    name: "Matthias Adrian",
    phone: "0152 5873-9054",
    phoneInternational: "+4915258739054",
    img: "/img/m-adrian.jpg",
  },
  mvo: {
    name: "Michael Vogelbacher",
    phone: "0171 9760-212",
    phoneInternational: "+491719760212",
    img: "/img/m-vogelbacher.jpg",
  },
  psk: {
    name: "Pina Davies",
    phone: "0151 5586-1980",
    phoneInternational: "+4915155861980",
    img: "/img/p-davies.jpg",
  },
}

const JobOfferTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
  jobArea,
  next,
}) => {
  const JobOfferContent = contentComponent || Content

  // TODO: Refactor to apropriate data structure
  var benefits = {
    career: BENEFITS.career.tech,
    lifeBalance: BENEFITS.lifeBalance.tech,
    money: BENEFITS.money.tech,
    organization: BENEFITS.organization.tech,
    coCreation: BENEFITS.coCreation.tech,
  }

  var jobContact = JOB_CONTACTS.psk
  switch (jobArea) {
    case "dev":
      // nothing, default values
      break
    case "sec":
      jobContact = JOB_CONTACTS.psk
      break
    case "pre-sales":
      jobContact = JOB_CONTACTS.psk
      break
    case "sales":
      jobContact = JOB_CONTACTS.psk
      benefits.money = BENEFITS.money.sales
      benefits.lifeBalance = BENEFITS.lifeBalance.sales
      benefits.career = BENEFITS.career.sales
      benefits.organization = BENEFITS.organization.sales
      benefits.coCreation = BENEFITS.coCreation.sales
      break
    case "admin":
      jobContact = JOB_CONTACTS.psk
      break
    case "legal":
      jobContact = JOB_CONTACTS.mvo
      benefits.career = BENEFITS.career.legal
      benefits.lifeBalance = BENEFITS.lifeBalance.legal
      break
    default:
      break
  }

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-4 has-text-primary">{title}</h2>

            {tags && tags.length ? (
              <div className="tags">
                {tags.map(tag => (
                  <Link
                    key={tag + `tag`}
                    className="tag"
                    to={`/tags/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Link>
                ))}
              </div>
            ) : null}

            <JobOfferContent content={content} />
            <p />
            <h2 className="title is-4 has-text-primary">
              Und das kannst Du von uns erwarten
            </h2>
            <div>
              <p>
                Wir wollen mehr sein als nur ein Job, wir möchten gemeinsam
                etwas bewegen:
              </p>
              <ul>
                <li>
                  <strong>Weiterbildung: </strong>
                  <em>
                    "Wer aufhört besser zu werden, hat aufgehört gut zu sein“
                  </em>{" "}
                  - 20% der Arbeitszeit gehört daher der Weiterbildung, z.B. für
                  neue Tools und Technologien, Konferenzen, Community Work oder
                  persönlichen Austausch im Team.
                </li>
                <li>
                  <strong>Mitgestaltung: </strong> {benefits.coCreation}
                </li>
                <li>
                  <strong>Kundennähe: </strong> Der enge und intensive Austausch
                  mit unseren Kunden stellt sicher, dass Deine Arbeit stets Sinn
                  stiftet und in hoher Wertschätzung mündet.
                </li>
                <li>
                  <strong>Selbstorganisation: </strong> {benefits.organization}
                </li>
                <li>
                  <strong>Balanced Life: </strong> {benefits.lifeBalance}
                </li>
                <li>
                  <strong>Top-Notch Equipment: </strong>Ob MacBook, Windows oder
                  Linux, ob iPhone oder Android, du findest eine große Auswahl
                  bei uns, wir sparen nicht an der technischen Ausstattung, denn
                  Produktivität und der Feelgood-Faktor sind uns sehr wichtig.
                </li>
                <li>
                  <strong>
                    Attraktive und leistungsorientierte Vergütung:{" "}
                  </strong>{" "}
                  {benefits.money}
                </li>
                <li>
                  <strong>Karriere: </strong> {benefits.career}
                </li>
              </ul>
            </div>

            <p />
            <div className="columns">
              <div className="column">
                {<JobOfferContact contact={jobContact} />}
                <Apply title={title} />
              </div>
              <div className="column mt-2">
                <img
                  src="/img/kununu.svg"
                  width="150px"
                  height="150px"
                  style={{ objectFit: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

JobOfferTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const JobOffer = ({ data }) => {
  const { markdownRemark: job } = data

  return (
    <Layout>
      <Hero
        title="Offene Stellen"
        subtitle="Werde Teil unseres Teams"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="getting digital things done"
          subtitle="colenio ist
              ein innovativer IT-Dienstleister, der gemeinsam mit seinen mehr
              als 150 Kunden digitale Veränderungsvorhaben zum Erfolg führt.
              Kunden schätzen insbesondere den durchgängig hohen
              Qualitätsanspruch der Ergebnisse und die thematische Breite der
              Beratungsleistungen. Diese reichen von der Entwicklung großer
              Software- und Cloudarchitekturen, DevOps und Data Science über das
              gesamte konzeptionelle und technische Spektrum der Informations-
              und IT-Sicherheit bis zu IT-spezifischen Rechts- und
              Compliance-Aspekten wie Datenschutz und Consent Management."
        />
        <JobOfferTemplate
          content={job.html}
          contentComponent={HTMLContent}
          helmet={
            <Helmet titleTemplate="%s | Career">
              <title>{`${job.frontmatter.title}`}</title>
            </Helmet>
          }
          tags={job.frontmatter.tags}
          title={job.frontmatter.title}
          jobArea={job.frontmatter.jobArea}
        />
      </div>
    </Layout>
  )
}

JobOffer.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default JobOffer

export const pageQuery = graphql`
  query JobOfferByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        jobArea
      }
    }
    hero: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
