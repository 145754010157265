import React from 'react'

const JobOfferContact = ({
  contact: { name, phone, phoneInternational, img },
}) => {
  return (
    <div>
      <h2 className="title is-4 has-text-primary">Ansprechpartner</h2>
      <img
        src={img}
        width="150px"
        alt="contact-image"
        height="150px"
        style={{ objectFit: 'cover' }}
      />
      <p>{name}</p>
      <p>
        <a href={`tel:${phoneInternational}`}>{phone}</a> |{' '}
        <a href="mailto:jobs@colenio.de"> jobs@colenio.de</a>
      </p>
    </div>
  )
}

export default JobOfferContact
